import React, { Component } from 'react';
import {
    Tag, ButtonGroup, Button, Intent,
} from '@blueprintjs/core';
import { __ } from '../../utilities/i18n';
import ScalarField from './ScalarField';

//  Scalar  String

export default class String extends ScalarField {

}
