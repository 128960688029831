import React, { Component, Fragment } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import $ from 'jquery';
import { withRouter } from 'react-router';
import { compose } from 'react-apollo';
import DNDIcon from './DNDIcon';
import Loading from '../../layouts/utilities/Loading';
import { __ } from '../../layouts/utilities/i18n';

class YandexMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hideMarker: false,
            DNDIconX: 1,
            DNDIconY: 1,
            isDragging: false,
            places: this.props.places,
            events: this.props.events,
            teams: this.props.teams,
        };
        const el = document.createElement('script');
        el.context = this;
        el.type = 'text/javascript';
        el.src = `https://api-maps.yandex.ru/2.1/?load=package.full&lang=ru_RU&apikey=${process.env.REACT_APP_YANDEX_MAP_API_KEY}`;
        el.async = true;
        el.id = 'ymap-jssdk';
        el.onload = function () {
            window.ymaps.ready(() => {
                el.context.init_map();// setState({loadYmap:1});
            });
        };
        document.getElementsByTagName('head')[0].appendChild(el);
        window.mapState = this.props.context;
        window.yandex_map_state = this;
        window.mapData = {};
        window.getPlaceLink = function (n) {
            window.mapState.getPlaceLink(n);
        };
        window.getPlaceDialog = function (n) {
            window.mapState.getPlaceDialog(n);
        };
        window.getEventDialog = function (n, p) {
            window.mapState.getEventDialog(n, p);
        };
        window.getClusterDialog = function (data) {
            window.mapState.getClusterDialog(data);
        };
        window.deletePlace = function (id) {
            // console.log("window.deletePlace", id)
            window.yandex_map_state.deletePlace(id);
        };
        window.onCreate = function () {
            // console.log("window.onCreate")
            window.yandex_map_state.onCreate();
        };
    }

    componentWillUnmount() {
        delete window.getPlaceLink;
        delete window.getPlaceDialog;
        delete window.getClusterDialog;
        delete window.deletePlace;
        delete window.onCreate;
        delete window.yandex_map_state;
        delete window.mapState;
    }

    deletePlace(id) {
        // console.log("deletePlace", id);
        let gO;
        this.myMap.geoObjects.each((e, i) => {
            if (typeof e.properties === 'object');
            {
                if (e.properties) {
                    gO = e;
                }
            }
        });
        if (gO) {
            this.setState({ DNDIconX: 1, DNDIconY: 1, isDragging: false });
            this.myMap.geoObjects.remove(gO);
        }
    }

    onCreate() {
        console.log('onCreate', this.state);
        this.props.onStartCreate({
            ...this.state,
            title: __(`new ${this.state.DNDType}`),
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.startDND) {
            // console.log("nextProps.startDND", nextProps.startDND);
            this.startDND(nextProps.startDND);
        }
        if (nextProps.places !== this.state.places) {
            this.setState({ places: nextProps.places }, function () {
                $('#map').empty();
                this.loaded = false;
                this.init_map();
            });
        }
    }

    startDND = data => {
        // console.log("startDND", data);
        if (data.deletePlace) this.deletePlace(-1);
        if (!data.isDragging) return;
        const coords = $('#map').offset();
        const state = {
            hideMarker: false,
            DNDIconX: 0 - coords.left,
            DNDIconY: 0 - coords.top,
            isDragging: data.isDragging,
            DNDType: data.DNDType,
            DNDStyle: data.DNDStyle,
        };
        this.deletePlace(-1);
        this.setState(state);
    }

    render() {
        // console.log(this.state.DNDIconX, this.state.isDragging)
        return <div className="map_container">
            <div className="map" id="map"></div>
            <div style={{
                position: 'absolute', top: 0, left: 0, 'z-index': '40',
            }}
            >
                <DNDIcon
                    translateX={this.state.DNDIconX}
                    translateY={this.state.DNDIconY}
                    onDragStart={this.onDragStart}
                    onDrag={this.onDrag}
                    onDragEnd={this.onDragEnd}
                    isDragging={this.state.isDragging}
                    style={{
                        ...this.state.DNDStyle,
                        display: this.state.hideMarker ? 'none' : 'inline-flex',
                    }}
                />
            </div>
        </div>;
    }

    init_map() {
        if (this.loaded) return;
        this.loaded = true;
        if (!document.getElementById('map')) return;
        // console.log( "events: ", this.props.events );
        // console.log( "places: ", this.state.places );
        // console.log( "teams: ", this.props.teams );
        this.myMap = new window.ymaps.Map(
            'map',
            {
                center: [55.76, 37.64],
                zoom: 10,
                controls: ['zoomControl', 'searchControl'],
            },
            {
                searchControlProvider: 'yandex#search',
            },
        );
        const places = this.state.places || [];
        const events = this.props.events || [];
        const customItemContentLayout2 = window.ymaps.templateLayoutFactory.createClass(
            // Флаг "raw" означает, что данные вставляют "как есть" без экранирования html.
            '<div  class="ballon_header lead text-center font-weight-light">{{ properties.balloonContentHeader|raw }}</div>'
            + '<div class="ballon_body text-center">{{ properties.balloonContentBody|raw }}</div>',
            // +'<div class="ballon_footer text-center text-secondary">{{ properties.balloonContentFooter|raw }}</div>'
        );

        this.clusterer = new window.ymaps.Clusterer(
            {
                /// / Зададим макет метки кластера.
                clusterIconLayout: window.ymaps.templateLayoutFactory.createClass(
                    '<div class="clusterIcon"><div class="place_flag"></div>{{ properties.geoObjects.length }}</div>',
                ),
                preset: 'islands#invertedVioletClusterIcons',
                // groupByCoordinates: true,
                clusterDisableClickZoom: true,
                clusterOpenBalloonOnClick: false,
                clusterBalloonContentLayout: 'cluster#balloonCarousel',
                clusterBalloonItemContentLayout: customItemContentLayout2,
                clusterBalloonContentLayoutWidth: 230,
                clusterBalloonContentLayoutHeight: 110,
                // Макет метки кластера pieChart.
                clusterIconLayout: 'default#pieChart',
                // Радиус диаграммы в пикселях.
                clusterIconPieChartRadius: 35,
                // Радиус центральной части макета.
                clusterIconPieChartCoreRadius: 15,
                // Ширина линий-разделителей секторов и внешней обводки диаграммы.
                clusterIconPieChartStrokeWidth: 3,
                clusterHideIconOnBalloonOpen: false,
                geoObjectHideIconOnBalloonOpen: false,
                gridSize: 128,
            },
            {
                gridSize: 128,
            },
        );

        places.forEach((el, i) => {
            // console.log(el);
            // события места
            const events = el.events || [];
            const { chat } = el;
            // место

            // Создаём макет содержимого.
            const type_new = el.type_new || {};
            const pfill = type_new.color;
            const MyIconContentLayout = window.ymaps.templateLayoutFactory.createClass(
                `<div style="width:30px;height:30px; border:6px solid ${pfill}; -webkit-border-radius:100%;border-radius:100%;">$[properties.iconContent]</div>`,
            );
            // console.log(type_new);
            const flag = el.is_events_active
                ? "<div class='place_flag'></div>"
                : '';
            const place = new window.ymaps.Placemark(
                [el.geo[0], el.geo[1]],
                {
                    data_id: el._id,
                    data_type: 'place',
                    hintContent: `${__(type_new.title)} ${el.title}`,
                    balloonContentHeader: this.props.context.getPlaceHeader(el),
                    iconContent: `<div style='background-image:url(assets/img/home4.svg); width:28px; height:28px; background-size:cover; fill:#FF0000;'>${flag}</div>`,
                },
                {
                    // Необходимо указать данный тип макета.
                    iconLayout: 'default#imageWithContent',
                    // Своё изображение иконки метки.
                    iconImageHref: 'assets/img/circle.svg',
                    // Размеры метки.
                    iconImageSize: [42, 42],
                    // Смещение левого верхнего угла иконки относительно
                    // её "ножки" (точки привязки).
                    iconImageOffset: [-21, -21],
                    iconImageFill: '#FFFFFF',
                    // Смещение слоя с содержимым относительно слоя с картинкой.
                    iconContentOffset: [0, 0],
                    // Макет содержимого.
                    iconContentLayout: MyIconContentLayout,

                    hasBalloon: false,
                    hasHint: true,
                    // preset: 'islands#governmentCircleIcon',
                    iconColor: pfill,
                },
            );
            place.events.add('click', evt => window.getPlaceDialog(el._id));
            this.clusterer.add(place);
        });
        events.forEach((el, i) => {
            /*
            const evnt = new window.ymaps.Placemark(
                [el.geo[0], el.geo[1]],
                {
                    data_id: el._id,
                    data_type:"event",
                    balloonContent: __(el.external_system === "wp_fest" ?  "Festival" : "Event") + " " + el.title,
                    hintContent: __(el.external_system === "wp_fest" ?  "Festival" : "Event") + " " + el.title,
                    balloonContentHeader: this.getEventHeader(el),

                    balloonContentBody: this.props.context.getEventBody(el),
                    balloonContentFooter: this.props.context.getEventFooter(el)
                },
                {
                    hasBalloon: false,
                    hasHint: true,
                    preset: 'islands#redDotIcon',
                    iconColor: el.external_system !== "wp_fest" ? '#dc3545' : "#dc359b",
                })
            */
            // Создаём макет содержимого.
            const pfill = el.external_system !== 'wp_fest' ? '#dc3545' : '#dc359b';
            const MyIconContentLayout = window.ymaps.templateLayoutFactory.createClass(
                `<div style="width:30px;height:30px; border:6px solid ${pfill}; -webkit-border-radius:100%;border-radius:100%;">$[properties.iconContent]</div>`,
            );
            const evnt = new window.ymaps.Placemark(
                [el.geo[0], el.geo[1]],
                {
                    data_id: el._id,
                    data_type: 'event',
                    balloonContentHeader: this.getEventHeader(el),
                    hintContent: `${__(el.external_system === 'wp_fest' ? 'Festival' : 'Event')} ${el.title}`,
                    iconContent: "<div style='background-image:url(assets/img/event.svg); width:28px; height:28px; background-size:cover; fill:#FF0000;'></div>",
                },
                {
                    // Необходимо указать данный тип макета.
                    iconLayout: 'default#imageWithContent',
                    // Своё изображение иконки метки.
                    iconImageHref: 'assets/img/circle.svg',
                    // Размеры метки.
                    iconImageSize: [42, 42],
                    // Смещение левого верхнего угла иконки относительно
                    // её "ножки" (точки привязки).
                    iconImageOffset: [-21, -21],
                    iconImageFill: '#FFFFFF',
                    // Смещение слоя с содержимым относительно слоя с картинкой.
                    iconContentOffset: [0, 0],
                    // Макет содержимого.
                    iconContentLayout: MyIconContentLayout,

                    hasBalloon: false,
                    hasHint: true,
                    iconColor: pfill,
                    // preset: 'islands#governmentCircleIcon',
                },
            );

            evnt.events.add('click', evt => window.getEventDialog(el._id));
            this.clusterer.add(evnt);
        });
        this.myMap.geoObjects.add(this.clusterer);
        this.clusterer.events.add('click', evt => window.getClusterDialog(
            evt.get('target').properties._data.geoObjects,
            __('Cluster'),
        ));
    }

    getEventHeader(el) {
        return el.external_system == 'wp_fest'
            ? `<span class='small text-rose'><i class='fas fa-calendar-alt'></i> ${__('Festival')}</span> ${el.title}`
            : `<span class='small text-red'><i class='fas fa-calendar-alt'></i> ${__('Event')}</span> ${el.title}`;
    }

    onDragEnd = state => {
        const map = this.myMap;
        const coords = $('#map').offset();
        const globalPixelPoint = map.converter.pageToGlobal([
            state.translateX,
            state.translateY,
        ], map.getZoom());
        const new_mark_coords = map.options.get('projection').fromGlobalPixels(
            map.converter.pageToGlobal([state.translateX, state.translateY]), map
                .getZoom(),
        );
        const placemark = new window.ymaps.Placemark(
            new_mark_coords,
            {
                data_id: -1,
                data_type: this.state.DNDType,
                balloonContentHeader: __(`new ${this.state.DNDType}`),
                balloonContentBody: `<div class=' text-secondary mb-2' style='max-width:250px;'>${__("Object has start register after you send it's options.")}</div><a class='btn btn-sm btn-primary' href='javascript:window.onCreate( );'>${
                    __('Edit for creation')
                }</a> <a class='btn btn-sm btn-danger' href='javascript:window.deletePlace(-1);'>${
                    __('Cancel')
                }</a>`,
                hintContent: __(`new ${this.state.DNDType}`),
            },
            {
                hasBalloon: true,
                hasHint: true,
                preset: 'islands#redSportIcon',
                visible: false,
                balloonCloseButton: false,
            },
        );
        map.geoObjects.add(placemark);
        placemark.balloon.open();
        window.scrollTo(0, 10000);

        this.setState({
            new_mark_coords,
            hideMarker: true,
            DNDIconX: 1,
            DNDIconY: 1,
            isDragging: false,
        });
        this.props.onFinishNew();
    }
}
export default compose(
    withRouter,
)(YandexMap);
