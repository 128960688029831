import React, { Component, Fragment } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import $ from 'jquery';
import { withRouter } from 'react-router';
import {
    AnchorButton, Button, ButtonGroup, Classes, Dialog, Intent, Tooltip, FormGroup, InputGroup, TextArea,
} from '@blueprintjs/core';
import {
    String, URL, DateTime, Radio, Geo, TextField,
} from '../../layouts/FieldInput';
import Loading from '../../layouts/utilities/Loading';
import { __ } from '../../layouts/utilities/i18n';

class NewTeamForm extends Component {
    state={ ...this.props };

    render() {
        console.log('22');
        return <section>
            <String
                field="title"
                title={__('Title')}
                editable
                value={this.state.title}
                vertical={false}
                on={this.onTitle}
            />
            <TextField
                field="description"
                title={__('Description')}
                editable
                on={this.onDescription}
                value={this.state.description}
            />
            {
                this.state.geo || this.state.new_mark_coords
                    ? <Geo
                        field="geo"
                        title={__('Geo')}
                        editable={false}
                        value={this.state.geo || this.state.new_mark_coords}
                    />
                    : null
            }
        </section>;
    }

    onTitle = (value, field) => {
        console.log(field, value);
        this.setState({ title: value });
        this.props.on(value, 'title');
    }

    onDescription = (value, field) => {
        this.setState({ description: value });
        this.props.on(value, 'description');
    }

    onExternal_system = evt => {
        // console.log("type", evt);
        this.setState({ type: evt });
        this.props.on(evt, 'type');
    }
}
export default NewTeamForm;
