import React, { Component, Fragment } from 'react';
import BasicState from '../../layouts/BasicState';
import config from '../../config/config.json';

class CalendarState extends BasicState {
    myState = () => {
        const iframe = `<iframe src="https://calendar.google.com/calendar/embed?src=${config.google_calendar_id}&ctz=Europe%2FMoscow" style="border: 0" width="100%" height="100%" frameborder="0" scrolling="no"></iframe >`;
        return <div
            dangerouslySetInnerHTML={{ __html: iframe }}
            style={{
                display: 'flex',
                justifyContent: 'stretch',
                alignItems: 'stretch',
                width: '100%',
                height: '100%',
            }}
        />;
    }

    getRoute = () => 'calendar'
}
export default CalendarState;
