import React, { Component, Fragment } from 'react';
import {
    Tag, Popover, Classes, Button, ButtonGroup,
    Intent,
} from '@blueprintjs/core';
import { __ } from '../../utilities/i18n';

export default class String extends Component {
    state = { isOpen: false }

    render() {
        return <>

            <Popover
                popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                portalClassName="foo"
                isOpen={this.state.isOpen}
                content={
                    <div className="p-2">
                        <div className="font-weight-light text-center">{__('Delete this?')}</div>
                        <ButtonGroup>
                            <Button fill onClick={this.onRemove} intent={Intent.DANGER} minimal>
                                {__('Yes')}
                            </Button>
                            <Button fill onClick={this.onToggle} intent={Intent.SUCCESS} minimal>
                                {__('No')}
                            </Button>
                        </ButtonGroup>
                    </div>
                }
            >
                <Tag
                    minimal
                    large={false}
                    onRemove={this.onToggle}
                    onClick={this.onClick}
                    className="m-1"
                    intent={Intent.PRIMARY}

                >
                    {this.props.value}
                </Tag>
            </Popover>
        </>;
    }

    onRemove =() => {
        this.setState({ isOpen: !this.state.isOpen });
        this.props.onRemove(this.props._id);
    }

    onClick =() => {
        //
    }

    onToggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }
}
