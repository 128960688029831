import React, { Component } from 'react';
import {
    Tag, ButtonGroup, Button, Intent,
} from '@blueprintjs/core';
import { __ } from '../../utilities/i18n';
import ColorPicker from '../../utilities/ColorPicker';
import ScalarField from './ScalarField';
import { getTypeSelector } from '../../schema';

export default class DataTypeSelector extends ScalarField {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
        };
    }

    isDesabled() {
        const {
            field, title, value, extended_link, external_link_data,
        } = this.props;
        return <div className={`datetimer ${this.props.className}`}>
            <div className="px-0 my-2">
                {
                    this.props.value
                        ? <Tag minimal>
                            { `${this.props.value} `}
                        </Tag>
                        : null
                }
            </div>
        </div>;
    }

    isEnabled() {
        return <div className={`datetimer w-100 ${this.props.className}`}>
            { getTypeSelector({ onChange: this.onChange, selected: this.state.value }) }
        </div>;
    }

    onChange = evt => {
        this.setState({ value: evt.currentTarget.value });
        this.on(evt.currentTarget.value);
    }

    on = value => {
        this.props.on(value, this.props.field, this.props.title);
    }
}
