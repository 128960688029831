import React, {
    Component, Fragment, useContext, useState,
} from 'react';
import gql from 'graphql-tag';
import Graph from 'vis-react';
import { compose } from 'recompose';
import { loader } from 'graphql.macro';
import { Query, withApollo, graphql } from 'react-apollo';
import { withRouter } from 'react-router';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import BasicStateFunctional from '../BasicStateFunctional';
import FormEditState from '../FormEditState';
import { onSaveGql } from '../../layouts/schema/ecosystem';

const CompetencesMapState2 = function (props) {
    const [state, setState] = useState({});

    const getPrecedents = useQuery(gql`
    query getPrecedents {
      getPrecedents {
        _id title competences {_id title} subject {_id first_name second_name} project {_id title}
      }
    }
  `, {
        client: props.client,
    });
    const getProjects = useQuery(gql`
    query getProjects {
        getProjects {
            _id title roles {_id}
      }
    }
  `, {
        client: props.client,
    });
    const [loadCompetences, getCompetences] = useLazyQuery(gql`
    query getCompetences {
        getCompetences {
          _id title children_competences {_id title}
        }
      }
  `, {
        client: props.client,
    });
    if (getPrecedents.loading || getCompetences.loading) return <p>Loading ...</p>;
    if (!getCompetences.data) {
        loadCompetences();
        return null;
    }
    const graph = { nodes: {}, edges: [] };
    const options = {

        interaction: {
        },
        physics: {
            solver: 'barnesHut',
            barnesHut: {
                springLength: 200,
                avoidOverlap: 0.5,
            },
            stabilization: {
                enabled: true,
                iterations: 0,
                fit: true,
            },
        },
        edges: {
            smooth: {
                enabled: true,
            },
        },
        layout: {
            improvedLayout: false,
            randomSeed: 0,
        },
    };
    const competences = getCompetences.data.getCompetences;
    competences.forEach(e => {
        graph.nodes[e._id] = ({
            id: e._id,
            type: 'competence',
            label: e.title,
            font: {
                size: 15,
                color: 'white',
            },
            color: 'red',
        });
        if (e.children_competences) {
            e.children_competences.forEach(e2 => {
                graph.edges.push({
                    from: e2._id,
                    to: e._id,
                    nocolor: {
                        color: 'white',
                        highlight: 'white',
                    },
                });
            });
        }
    });
    const precedents = getPrecedents.data.getPrecedents;
    precedents.forEach(e => {
        graph.nodes[e._id] = ({
            id: e._id,
            label: e.title,
            type: 'precedent',
            font: {
                size: 15,
                color: 'black',
            },
            color: 'yellow',
        });
        if (e.competences) {
            e.competences.forEach(e2 => {
                graph.edges.push({
                    from: e._id,
                    to: e2._id,
                    nocolor: {
                        color: 'white',
                        highlight: 'white',
                    },
                });
            });
        }
        graph.nodes[e.subject._id] = ({
            id: e.subject._id,
            type: 'person',
            label: `${e.subject.first_name} ${e.subject.second_name}`,
            font: {
                size: 15,
                color: 'white',
            },
            color: 'green',
        });
        graph.edges.push({
            from: e.subject._id,
            to: e._id,
            nocolor: {
                color: 'white',
                highlight: 'white',
            },
        });
        if (e.project) {
            graph.nodes[e.project._id] = ({
                id: e.project._id,
                type: 'project',
                label: e.project.title,
                font: {
                    size: 15,
                    color: 'black',
                },
                color: 'lightblue',
            });
            graph.edges.push({
                from: e.project._id,
                to: e._id,
                nocolor: {
                    color: 'white',
                    highlight: 'white',
                },
            });
        }
    });

    const projects = getProjects.data.getProjects;
    projects.forEach(e => {
        graph.nodes[e._id] = ({
            id: e._id,
            type: 'project',
            label: e.title,
            font: {
                size: 15,
                color: 'black',
            },
            color: 'lightblue',
        });
        if (e.roles) {
            e.roles.forEach(e2 => {
                graph.edges.push({
                    from: e2._id,
                    to: e._id,
                    dashes: true,
                    nocolor: {
                        color: 'white',
                        highlight: 'white',
                    },
                });
            });
        }
    });

    const nodes_object = graph.nodes;
    graph.nodes = Object.values(graph.nodes);

    const events = {
        doubleClick(e) {
            console.log(e);
            if (e.nodes[0]) {
                const node = nodes_object[e.nodes[0]];
                if (node.type == 'competence') {
                    props.history.push(`/resources/education/competences/${node.id}`);
                }
                if (node.type == 'person') {
                    props.history.push(`/system/persons/${node.id}`);
                }
                if (node.type == 'precedent') {
                    props.history.push(`/resources/education/precedents/${node.id}`);
                }
                if (node.type == 'project') {
                    props.history.push(`/system/projects/${node.id}`);
                }
            }
        },
    };

    return <BasicStateFunctional
        title="Образовательная карта"
        {...props
        }
    >
        <script src="https://cdnjs.cloudflare.com/ajax/libs/vis/4.21.0/vis.min.js"></script>
        <link href="https://cdnjs.cloudflare.com/ajax/libs/vis/4.21.0/vis.min.css" rel="stylesheet" type="text/css" />
        <div refresh={state.refresh} className="position-relative bg-light height_100">
            <Graph
                graph={graph}
                options={options}
                events={events}
                style={{ height: '100%' }}
            />
        </div>
        <FormEditState
            // ID="5ca24daff4e53355854467f3"
            data_type="Competence"
            // isHiddenSave={true}
            isHiddenClose
            isHiddenDelete
            onChange={() => loadCompetences()}
        />
    </BasicStateFunctional>;
};

export default compose(

    withApollo,
    withRouter,
)(CompetencesMapState2);
