import React, { Fragment, Component } from 'react';
import {
    Tabs, Tab, Button, Classes, Dialog, Intent, Tooltip, Callout, Card,
} from '@blueprintjs/core';
import Moment from 'react-moment';
import moment from 'moment';

import { compose } from 'recompose';
import { loader } from 'graphql.macro';
import { Query, withApollo, graphql } from 'react-apollo';
import { withRouter } from 'react-router';
import gql from 'graphql-tag';
import { __ } from '../../layouts/utilities/i18n';
import PlaceEvent from './PlaceEvent';
import NewEventForm from './NewEventForm';

const getPlaces = loader('./graphql/getPlaces.graphql');
const deletePlaceEvent = loader('./graphql/deletePlaceEvent.graphql');
const changePlaceEvent = loader('./graphql/changePlaceEvent.graphql');
const addEventToPlace = loader('./graphql/addEventToPlace.graphql');

class PlaceEventTabs extends Component {
    state = {
        navbarTabId: 'list',
        title: __('New Event'),
        start_date: Date.now(),
        end_date: Date.now(),
        description: '',
        external_system: 'TimePad',
        events: this.props.events,
    }

    render() {
        return <div className="p-tab">
            <Tabs
                id="TabsExample"
                onChange={this.handleTabChange}
                selectedTabId={this.state.navbarTabId}
                vertical={false}
                animate={false}
                className="b3-tab-panel-primary"
            >
                <Tab id="list" title={__('actuals Events')} panel={this.actualEventsTab()} />
                {
                    this.props.currentUser
                        ? <Tab id="new" title={__('Register Event')} panel={this.newEventFormTab()} />
                        : null
                }
            </Tabs>
        </div>;
    }

    actualEventsTab() {
        const events = this.state.events.length
            ? this.state.events.map((e, i) => <li className="list-group-item" key={i}>
                <PlaceEvent
                    {...e}
                    place_id={this.props._id}
                    onSave={this.onChange}
                    onDelete={this.onDelete}
                />
            </li>)
            : <Callout>{__('There are no Events in the near future')}</Callout>;
        return <ul className="list-group">
            {events}
        </ul>;
    }

    newEventFormTab() {
        return <>
            <NewEventForm
                on={this.onNewForm}
                title={this.state.title}
                start_date={this.state.start_date}
                external_system={this.state.external_system}
            />
            <div className="row">
                <div className="col-9 offset-3 mt-3 mb-1">
                    <Button
                        text={__('Register Event')}
                        onClick={this.onAddEvent}
                        intent={Intent.DANGER}
                    />
                </div>
            </div>
        </>;
    }

    onAddEvent = () => {
        console.log(this.state);
        const dat = {
            title: this.state.title,
            description: this.state.description,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
        };
        console.log(dat);
        this.props.changePlaceEvent({
            variables: dat,
            update: (store, update1) => {
                console.log(update1.data.changePlaceEvent, this.props._id);
                this.props.addEventToPlace({
                    variables: {
                        place_id: this.props._id,
                        event_id: update1.data.changePlaceEvent._id,
                    },
                    update: (store, update) => {
                        // console.log(update.data.addEventToPlace);
                        const events_old = this.state.events;
                        events_old.push(update1.data.changePlaceEvent);
                        this.setState({ events: events_old });
                        this.setState({ navbarTabId: 'list' });
                    },
                });
            },
        });
    }

    onDelete = _id => {
        const dat = {
            id: _id,
        };
        this.props.deletePlaceEvent({
            variables: dat,
            update: (store, update) => {
                console.log(update.data.deletePlaceEvent);
                const events_old = this.state.events;

                if (update.data.deletePlaceEvent) {
                    const filtered = events_old.filter((value, index, arr) => {
                        console.log(value._id);
                        console.log(_id);
                        return value._id !== _id;
                    });

                    this.setState({ events: filtered });
                }
            },
            refetchQueries: [
                { query: getPlaces, variables: {} },
            ],
        });
    }

    onChange = (state, _id) => {
        state.id = _id;
        console.log(state, _id);
        this.props.changePlaceEvent({
            variables: state,
            update: (store, update) => {
                const events_old = this.state.events;
                const filtered = events_old.filter((value, index, arr) => {
                    console.log(value._id);
                    console.log(_id);
                    return value._id !== _id;
                });

                filtered.push(update.data.changePlaceEvent);
                this.setState({ events: filtered });
                console.log(update.data.changePlaceEvent);
            },
            refetchQueries: [
                { query: getPlaces, variables: {} },
            ],
        });
    }

    onNewForm = (field, value) => {
        console.log(field, value);
        const state = {};
        state[field] = value;
        this.setState(
            state,
            console.log(
                state,
                this.state,
            ),
        );
    }

    handleTabChange = tab => {
        this.setState({ navbarTabId: tab });
    }
}

export default compose(
    graphql(changePlaceEvent, { name: 'changePlaceEvent' }),
    graphql(addEventToPlace, { name: 'addEventToPlace' }),
    graphql(deletePlaceEvent, { name: 'deletePlaceEvent' }),
    withApollo,
    withRouter,
)(PlaceEventTabs);
