import React, { Component } from 'react';
import { Query } from 'react-apollo';
import {
    apolloFields, getQueryExternalFields, getVisibleValue, getQueryName,
} from '../../schema';
import ExtendedLink from '../scalars/ExtendedLink';
import Link from '../scalars/Link';
import Loading from '../../utilities/Loading';
import Selector from '../scalars/Selector';
import String from '../scalars/String';

export default function ExternalPlus(params) {
    const editable = typeof params.editable !== 'undefined' ? params.editable : true;
    const { field } = params;
    const visibled_value = getVisibleValue(params.component);
    const { external_state } = params;
    const { external_link_data } = params;
    const { vertical } = params;
    const { list } = params;
    const { addList } = params;

    const { extended_link } = params;
    const { inner_link } = params;

    const { title } = params;
    let { value } = params;
    const { onChange } = params;
    const { type } = params;
    // console.log(params)
    const query_gql = getQueryExternalFields(params.component, params.external_fields);
    const aq = getQueryName(params.component);

    if (extended_link) {
        // добавляем дополнительные поля в роутинг (сейчас только :id)
        let { route } = extended_link;
        if (extended_link.add) {
            switch (extended_link.add) {
            case ':id':
                route += value ? value._id : ' ';
                break;
            default:
                route = route;
            }
        }
        const visibled_value = getVisibleValue(params.component);
        return <ExtendedLink
            field={field}
            extended_link={route}
            title={title}
            _id={1}
            value={value ? value[visibled_value] : ' -- '}
            vertical={vertical}
            external_state={external_state}
            external_link_data={external_link_data}
        />;
    }
    if (inner_link == 1) {
        return <Link
            field={field}
            title={title}
            _id={1}
            value={value ? value[visibled_value] : ' -- '}
            external_state={external_state}
            vertical={vertical}
            external_link_data={external_link_data}
        />;
    }
    if (params.component) {
        const f = <Query query={query_gql}>
            {
                ({
                    loading, error, data, client,
                }) => {
                    if (loading) return <Loading />;

                    let listData = [];
                    if (addList) {
                        listData = data[aq].concat(addList);
                    } else if (list) {
                        listData = list;
                    } else {
                        // console.log(data , aq);
                        listData = data[aq];
                    }
                    // console.log(params);
                    if (listData && params.show_first) {
                        value = listData[0];
                    }
                    // console.log( value );
                    if (data) {
                        return <Selector
                            field={field}
                            editable={editable}
                            title={title}
                            value={value}
                            data={listData}
                            visibled_value={visibled_value}
                            vertical={vertical}
                            on={onChange}
                        />;
                    }
                }
            }
        </Query>;
        return f;
    }

    return <String
        field={field}
        title={title}
        editable={false}
        visibled_value={visibled_value}
        value={value ? value[visibled_value] : ''}
        vertical={vertical}
        on={onChange}
    />;
}
