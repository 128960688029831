import React, { Component, Fragment } from 'react';
import {
    Tabs, Tab, Button, Classes, Dialog, Intent, Tooltip, Callout, Card,
} from '@blueprintjs/core';
import { graphql, compose, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import { withRouter } from 'react-router';
import Loading from '../../layouts/utilities/Loading';
import { __ } from '../../layouts/utilities/i18n';

const getPlaces = loader('./graphql/getPlaces.graphql');

class PlaceList extends Component {
    render() {
        if (this.props.loadingPlaces) return <Loading />;

        const list = this.props.places.map((e, i) => <Button
            fill
            text={e.title}
            title={e.title}
            key={i}
            onClick={this.onClick}
            _id={e._id}
        />);
        /*
        console.log(this.props.places);
        const list = <Selector
                field={ "FIELD" }
                editable={ true }
                title={ __("Choose the Place to be merged") }
                value={ null }
                data ={ this.props.places }
                visibled_value={"title"}
                vertical={ true }
                on={this.onChange}
            />;
        */
        return <div>
            {list}
        </div>;
    }

    // <div style={{ overflow:"auto", flexGrow:100 }} >
    onClick = evt => {
        const _id = evt.currentTarget.getAttribute('_id');
        const title = evt.currentTarget.getAttribute('title');
        this.props.onChoose(_id, title);
    }

    onChange = newValue => {
        console.log(newValue);
    }
}
export default compose(
    graphql(getPlaces,
        {
            options: props => ({
                variables: {
                    near_of: props._id,
                },
                name: 'getPlaces',
            }),
            props: p => {
                // console.log("getPlaces", p);
                const { data } = p;

                let result = [];
                if (Array.isArray(data.getPlaces)) {
                    result = data.getPlaces.filter(place => place._id !== p.ownProps._id);
                    // data.getPlaces
                }

                return { loadingPlaces: data.loading, places: result };
            },
        }),
    withApollo,
    withRouter,
)(PlaceList);
