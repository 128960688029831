import React, { Component, Fragment } from 'react';
// import SubMenuComponent from "./SubMenuComponent";
import { loader } from 'graphql.macro';
import { withRouter } from 'react-router';

import { compose, graphql } from 'react-apollo';
import Loading from '../../layouts/utilities/Loading';
import i18n from '../../layouts/i18n';

class Aside extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.data.loading) return <Loading />;
        let mobile;
        // console.log( this.props.location.state );
        if (this.props.location.state) {
            if (
                this.props.location.state.uniq == 'teatchers'
                || this.props.location.state.parent_id == 'matherials'
                || this.props.location.state.post_title == i18n.t('Matherials')
                || this.props.location.state.uniq == 'olympiads'
                || (this.props.location.state.matherials && this.props.location.state.matherials.length > 0)
            ) mobile = this.getMobile();
        }
        return <>
            <div className="aside">

            </div>
            {mobile}
        </>;
    }

    getMobile = () => <div className="aside">
        <div className="title">
            <i className="fas fa-mobile-alt mr-2"></i>
            {i18n.t('Mobile application')}
        </div>
        <div className="content small">
            {i18n.t('')}
        </div>
        <div className="content">

        </div>
    </div>
}

Aside.propTypes = {
};

Aside.defaultProps = {
};

export default compose(
    withRouter,
)(Aside);
