import React, {
    Component, Fragment, useContext, useState,
} from 'react';

import { compose } from 'recompose';
import { loader } from 'graphql.macro';
import {
    graphql, Mutation, Query, withApollo,
} from 'react-apollo';
import { withRouter } from 'react-router';
import { Card, Intent } from '@blueprintjs/core';
import { NavLink } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { __ } from '../../layouts/utilities/i18n';
import { mutationToken, mutationAvtorize, queryUserInfo } from '../../layouts/schema';
import { AppToaster } from '../../layouts/utilities/blueUtils';
import { assertion_token } from '../../layouts/config';

import BasicStateFunctional from '../BasicStateFunctional';
import BasicState from '../../layouts/BasicState';
import UserContext from '../../layouts/userContext';

const LoginState = function (props) {
    // state={login: "", password: ""}
    const [state, setState] = useState({});
    const context = useContext(UserContext);

    const login = e => { setState({ ...state, login: e.currentTarget.value }); };
    const passWord = e => { setState({ ...state, password: e.currentTarget.value }); };
    const [avtorize] = useMutation(mutationAvtorize(), { client: props.client });
    const [token] = useMutation(mutationToken(), { client: props.client });
    const [userinfo] = useLazyQuery(queryUserInfo(), {
        client: props.client,
        fetchPolicy: 'network-only',
        onCompleted:
        data => {
            context.setUser(data.userInfo);
            props.history.replace('/');
        },
    });

    const onLogin = (evt, avtorize, token, userinfo) => {
        evt.preventDefault();
        avtorize({
            variables:
                {
                    input: {
                        assertion: assertion_token(),
                        user_code: state.password || '',
                        login_hint: state.login || '',
                        scope: [''],
                    },
                },
            update: (store, { data: { authorize } }) => {
                if (authorize.auth_req_id) {
                    // TODO поставить вилку с/без scope
                    token({
                        variables:
                            {
                                input: {
                                    grant_type: 'ciba',
                                    assertion: assertion_token(),
                                    auth_req_id: authorize.auth_req_id,
                                },
                            },
                        update: (store, { data: { token } }) => {
                            AppToaster.show({
                                intent: Intent.SUCCESS,
                                icon: 'tick',
                                message: __('You enter by User'),
                            });
                            setState({ logged: 2 });
                            localStorage.setItem(
                                'token',
                                token.access_token,
                            );
                            props.client.query({ query: queryUserInfo(), fetchPolicy: 'network-only' }).then(
                                result => {
                                    context.setUser(result.data.userInfo);
                                    props.history.replace('/');
                                },
                            );
                            // TODO: get userInfo and put to store
                        },
                        // refetchQueries: [ { query: queryUserInfo(), variables: {}}]
                    });

                    setState({ logged: 1, auth_req_id: authorize.auth_req_id });
                }
            },
        });
    };
    return <BasicStateFunctional title="Вход" {...props}>
        <div className="layout-center">
            <Card
                interactive
                className="layout-message p-4 layout-center"
            >
                <div className="title">{__('Enter')}</div>
                <input
                    type="text"
                    className="w-100 input"
                    placeholder={__('enter login')}
                    name="login"
                    onChange={login}
                    // value={state.login}
                />
                <input
                    type="password"
                    className="w-100 input"
                    placeholder={__('enter password')}
                    name="password"
                    onChange={passWord}
                    // value={state.password}
                />
                <div className="btn btn-primary w-100" onClick={evt => onLogin(evt, avtorize, token, userinfo)}>
                    {__('log in')}
                </div>
                <NavLink
                    className="btn btn-link w-100"
                    to="/register"
                >
                    {__('register')}
                </NavLink>
            </Card>
        </div>
    </BasicStateFunctional>;
};

export default compose(

    withApollo,
    withRouter,
)(LoginState);
