import React, { Fragment } from 'react';
import BasicState from '../../layouts/BasicState';
import { Intent } from '@blueprintjs/core';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { compose } from 'recompose';
import { loader } from 'graphql.macro';
import {
    Query, withApollo, graphql, Mutation,
} from 'react-apollo';
import { withRouter } from 'react-router';
import gql from 'graphql-tag';
import { AppToaster } from '../../layouts/utilities/blueUtils';
import { __ } from '../../layouts/utilities/i18n';
import Propfile from '../ProfileState/profile/Profile';
import Loading from '../../layouts/utilities/Loading';

import {
    getMutationArgs,
    mutationEdit,
} from '../../layouts/schema';

class RegisterState extends BasicState {
    render() {
        const mutation_name = 'registerUser';
        const input_type_name = 'UserCurrentInput';
        const mutation_args = getMutationArgs('UserCurrentInput');
        const mutation = mutationEdit(mutation_name, input_type_name, mutation_args);

        return <Mutation mutation={mutation}>
            {(m_change, { data }) => this.profile(m_change)}
        </Mutation>;
    }

    profile(m_change) {
        const user = { avatar: '../img/user-logo.svg', roles: ['User'] };
        return <div className="layout-state">
            <div className="layout-state-head">
                <span className={`layout-state-logo ${this.state.route.icon}`} />
                <div className="layout-state-title">
                    { __(this.state.route.title) }
                </div>
            </div>
            <Propfile
                user={user}
                onEditField={this.onEditField}
                onChange={state => this.onChange(state, m_change)}
            />

        </div>;
    }

    onEditField = state => {
        console.log(state);
        // this.setState({})
    }

    getRoute = () => 'register'

    getExtends = () => null

    onChange = (state, m_change) => {
        console.log('state');
        console.log(state);
        delete state.avatar;
        delete state.roles;
        if (this.state.email === '') {
            AppToaster.show({
                intent: Intent.DANGER,
                icon: 'error',
                message: __('email not be empty'),
            });
            return;
        }
        if (this.state.name === '') {
            AppToaster.show({
                intent: Intent.DANGER,
                icon: 'error',
                message: __('name not be empty'),
            });
            return;
        }
        if (this.state.password === '') {
            AppToaster.show({
                intent: Intent.DANGER,
                icon: 'error',
                message: __('password not be empty'),
            });
            return;
        }
        m_change({
            variables:
                {
                    input: state,
                },
            update: (store, { data: { registerUser } }) => {
                AppToaster.show({
                    intent: Intent.SUCCESS,
                    icon: 'tick',
                    message: __('User registered'),
                });
                this.props.history.push('/');
            },
        });
    }
}

export default compose(
    withApollo,
    withRouter,
)(RegisterState);
