import React from 'react';

import { compose, mapProps } from 'recompose';
import { loader } from 'graphql.macro';
import {
    graphql, Query, withApollo, Mutation,
} from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router';
import Loading from '../../layouts/utilities/Loading';
import { __ } from '../../layouts/utilities/i18n';
import ChatListState from './ChatListState';
import BasicState from '../../layouts/BasicState';

class ContextChatState extends BasicState {
    alternateRender() {
        const locState = this.props.location.state;
        // console.log( locState );
        if (locState) {
            // если пользователь пришёл по линку
            return <ChatListState
                _id={this.props.match.params.id}
                route={this.state.route}
                orig={locState.orig}
                post={locState.post}
                sourse={locState.source_copy}
                comment="пользователь пришёл по линку"
            />;
        }

        // если пользователь пришёл по УРЛу
        const query2 = 'query getSourcePostLog { getSourcePostLog { source_id } }';
        const query = 'query getMyRepostLog { getMyRepostLog { _id post {_id post_text } source_copy {_id title } } }';
        const query_gql = gql`${query}`;
        //
        const f = <Query query={query_gql}>
            {
                ({
                    loading, error, data, client,
                }) => {
                    if (loading) return <Loading />;
                    return <ChatListState
                        _id={this.props.match.params.id}
                        route={this.state.route}
                        orig={{ _id: '000' }}
                        post={{ _id: 'AAA', post_text: 'BBB' }}
                        sourse={{ _id: 'CCC' }}
                        comment="пользователь пришёл по УРЛу"
                    />;
                }
            }
        </Query>;
        return f;
    }

    getRoute = () => 'context-chat'

    onEdit = () => {

    }
}
export default ContextChatState;
