import React from 'react';
import { compose } from 'recompose';
import { graphql, withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { loader } from 'graphql.macro';
import BasicState from '../../layouts/BasicState';
import { __ } from '../../layouts/utilities/i18n';
import LayoutIcon from '../../layouts/LayoutIcon';
import layouts from '../../layouts';
import config from '../../config/config.json';

const l_associate = loader('../../layouts/schema/graphql/associate.graphql');

class SocialsState extends BasicState {
    alternateRender() {
        const soc = layouts.external_systems;// ["telegram", "vk", "slack"];
        const btns = soc.map((e, i) => {
            let txt; let clss;
            if (this.state.for_channel == e) {
                clss = ' active ';
                txt = <div className="soctxt" dangerouslySetInnerHTML={{ __html: this.state.description }} />;
            }
            return <div
                key={i}
                className={`${e} soc ${clss}`}
            >
                {txt}
                <div className="logo" onClick={this.onAssociate} soc={e}>
                    <i className={`fab fa-${e} fa-2x`}></i>
                    <div className="small mt-1">{__(e)}</div>
                </div>

            </div>;
        });
        console.log(this.state.route.icon);
        return <div className="layout-state">
            <div className="layout-state-head">
                <LayoutIcon
                    isSVG
                    src={this.state.route.icon}
                    className="layout-state-logo "
                />
                <div className="layout-state-title">
                    { __(this.state.route.title) }
                </div>
            </div>
            <div className="row">
                <div className="d-flex col-12 micro-columns">
                    {btns}
                </div>
            </div>
        </div>;
    }

    getRoute = () => 'socials'

    onAssociate = evt => {
        const for_channel = evt.currentTarget.getAttribute('soc');
        this.props.associate({
            variables:
            {
                input:
                {
                    authenticator_type: 'otp',
                    for_channel,
                },
            },
            update: (store, { data: { associate } }) => {
                let burl;
                let description;
                switch (for_channel) {
                case 'telegram':
                    burl = `${config.telegram_bot}?start=${associate.user_code}`;
                    description = `Введите <a target='_blank' href='${burl}'>${burl}</a>`;
                    break;
                case 'vk':
                    burl = config.vk_bot;
                    description = `Введите <span class='socl'>/connect ${associate.user_code}</span>  <a target='_blank' href='${burl}'>${burl}</a> `;
                    break;
                case 'slack':
                    burl = config.slack_bot;
                    description = `Введите <span class='socl'>/connect ${associate.user_code}</span>  <a target='_blank' href='${burl}'>${burl}</a> `;
                    break;
                default:
                    description = '';
                }

                this.setState({ description, for_channel });
                console.log(description);
                // associate.authenticator_type
                // associate.user_code
            },
        });
    }
}

export default compose(
    graphql(l_associate, { name: 'associate' }),
    withApollo,
    withRouter,
)(SocialsState);
