import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { apolloFields, getQueryExternalFields } from '../../schema/ecosystem';
import Loading from '../../utilities/Loading';
import String from '../scalars/String';

export default function ComponentPlus(params) {
    const { field } = params;
    const visibled_value = params.visibled_value
        ? params.visibled_value
        : 'title';
    const { vertical } = params;
    const { list } = params;
    const { addList } = params;

    const { external_fields } = params;
    const query_gql = getQueryExternalFields(params.component, external_fields);
    const aq = apolloFields(params.component);

    const { title } = params;
    const { value } = params;
    const { onChange } = params;
    const { type } = params;

    //
    const f = <Query query={query_gql}>
        {
            ({
                loading, error, data, client,
            }) => {
                if (loading) return <Loading />;

                let listData = [];
                if (addList) {
                    listData = data[aq].concat(addList);
                } else if (list) {
                    listData = list;
                } else {
                    listData = data[aq];
                }
                // console.log( value );
                if (data) {
                    return <String
                        field={field}
                        title={title}
                        editable={false}
                        visibled_value={visibled_value}
                        value={value ? value[visibled_value] : ''}
                        vertical={vertical}
                        on={onChange}
                    />;
                }
            }
        }
    </Query>;
    return f;
}
