import React, { Component, Fragment } from 'react';
import { NavLink, Link } from 'react-router-dom';
import axios from 'axios';
import BasicState from '../../layouts/BasicState';
import { __ } from '../../layouts/utilities/i18n';

class HTMLSourceState extends BasicState {
    getRoute = route => {
        axios.get(this.props.html_source)
            .then(response => {
                let text = response.data;
                const SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
                while (SCRIPT_REGEX.test(text)) {
                    text = text.replace(SCRIPT_REGEX, '');
                }
                this.setState({ html: <div dangerouslySetInnerHTML={{ __html: text }} /> });
            });
        return this.props.route;
    }
}
export default HTMLSourceState;
