import React, { Component } from 'react';
import {
    Tabs, Tab, Button, Classes, Dialog, Intent, Tooltip, Icon, Callout,
} from '@blueprintjs/core';
import Moment from 'react-moment';
import moment from 'moment';
import { Map, Marker, MarkerLayout } from 'yandex-map-react'; // https://github.com/effrenus/yandex-map-react

import { compose } from 'recompose';
import { loader } from 'graphql.macro';
import { Query, withApollo, graphql } from 'react-apollo';
import { withRouter } from 'react-router';
import gql from 'graphql-tag';
import { __ } from '../../layouts/utilities/i18n';

const getCurrentUser = loader('./graphql/getCurrentUser.graphql');
const addMeToEvent = loader('./graphql/addMeToEvent.graphql');
const removeMeFromEvent = loader('./graphql/removeMeFromEvent.graphql');

class EventTabs extends Component {
    state = { navbarTabId: 'EventTab' }

    external_systems = {
        wp_fest: { title: __('Event created by WP-Fest service') },
        timepad: { title: __('Event created by TimePad service') },
    }

    render() {
        return <Tabs
            id="TabsExample"
            onChange={this.handleTabChange}
            selectedTabId={this.state.navbarTabId}
            vertical
            animated
        >
            <Tab
                id="EventTab"
                title={__(this.props.external_system == 'wp_fest' ? 'Festival' : 'Event')}
                panel={this.eventTab()}
            />
            <Tab
                id="MembersTab"
                title={__('Members')}
                panel={this.membersTab()}
            />
            <Tabs.Expander />
            <Button onClick={this.props.onClose} fill className="mt-4">{__('Close')}</Button>
        </Tabs>;
    }

    handleTabChange = navbarTabId => {
        this.setState({ navbarTabId });
    }

    eventTab() {
        console.log(this.props);
        const descr = this.props.description ? <div className="place-descr my-3">{this.props.description}</div> : '';
        const date = this.props.start_date ? moment(this.props.start_date).format('D MMMM YYYY') : __('Date not defined');
        const _link = this.props.external_url
            ? <a href={this.props.external_url} target="_blank" rel="noreferrer">{__('More information')}</a>
            : null;
        const map = this.props.geo
            ? <Map
                center={[
                    this.props.geo ? this.props.geo[0] : 55.754734,
                    this.props.geo ? this.props.geo[1] : 37.583314,
                ]}
                zoom={12}
                width="100%"
                height={120}
            >
                {
                    this.props.geo && Array.isArray(this.props.geo)
                        ? <Marker
                            lat={this.props.geo[0]}
                            lon={this.props.geo[1]}
                        >
                            <MarkerLayout>
                                <div style={{
                                    borderRadius: '50%', overflow: 'hidden', width: 22, height: 22, backgroundColor: '#FF0000', marginTop: -31,
                                }}
                                >

                                </div>
                            </MarkerLayout>
                        </Marker>
                        : null
                }
            </Map>
            : null;
        return <div className="p-tab">
            <div className="lead ">{this.props.title}</div>
            <div className="small text-secondary">
                { this.external_systems[this.props.external_system].title }
            </div>
            {descr}
            <div className="small text-secondary">
                { this.owner ? `${this.owner.name} ${this.owner.family_name}` : __('Author not exists')}
            </div>
            <div>
                {_link}
            </div>
            <div className=" mb-4">
                <Icon icon="calendar" className="text-secondary mr-1" />
                {' '}
                {date}
            </div>
            {map}

        </div>;
    }

    eventsTab() {
        const events = this.props.events.length
            ? this.props.events.map((e, i) => <li className="list-group-item" key={i}>
                <div className="float-right">
                    <Button intent={Intent.PRIMARY}>
                        {__('More')}
                    </Button>
                </div>
                <div className="font-weight-bold">{e.title}</div>

                <div className="">
                    {e.start_date ? moment(e.start_date).format('D MMMM YYYY') : __('Date not defined')}
                </div>
            </li>)
            : __('There are no Events in the near future');
        return <div className="p-tab">
            <ul className="list-group">
                {events}
            </ul>
        </div>;
    }

    membersTab() {
        const btn = this.isMember()
            ? <Button
                intent={Intent.DANGER}
                text={__('remove from members of ') + this.props.title}
                onClick={this.onRemoveMember}
            />
            : <Button
                intent={Intent.SUCCESS}
                text={__('add to members of ') + this.props.title}
                onClick={this.onAddMember}
            />;
        const members = this.state.members && this.state.members.length
            ? this.state.members.map((e, i) => <li className="list-group-item" key={i}>
                {`${e.name ? e.name : ''} ${e.family_name ? e.family_name : ''}`}
            </li>)
            : <Callout>{__('No members exists')}</Callout>;
        return <div className="p-tab">
            <ul className="list-group">
                {members}
            </ul>
            <div className="pt-3">
                {this.props.getCurrentUser.getCurrentUser && false ? btn : null}
            </div>
        </div>;
    }

    isMember() {
        return Array.isArray(this.state.members)
            ? this.state.members.filter(e => e._id === this.props.getCurrentUser.getCurrentUser._id).length > 0
            : false;
    }

    onAddMember = () => {
        const dat = {
            event_id: this.props._id,
        };
        console.log(dat);
        this.props.addMeToEvent({
            variables: dat,
            update: (store, update) => {
                console.log(update.data);
                this.setState({ members: update.data.addMeToEvent.members });
            },
        });
    }

    onRemoveMember = () => {
        const dat = {
            event_id: this.props._id,
        };
        console.log(dat);
        this.props.removeMeFromEvent({
            variables: dat,
            update: (store, update) => {
                console.log(update.data.removeMeFromEvent);
                this.setState({ members: update.data.removeMeFromEvent.members });
            },
        });
    }
}

export default compose(
    graphql(getCurrentUser, { name: 'getCurrentUser' }),
    graphql(addMeToEvent, { name: 'addMeToEvent' }),
    graphql(removeMeFromEvent, { name: 'removeMeFromEvent' }),
    withApollo,
    withRouter,
)(EventTabs);
