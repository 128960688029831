import React, { Component } from 'react';
import {
    Tag, ButtonGroup, Button, Intent, TextArea,
} from '@blueprintjs/core';
import { __ } from '../../utilities/i18n';
import ScalarField from './ScalarField';

export default class TextField extends ScalarField {
    isEnabled() {
        const { field, title } = this.props;
        const { value } = this.state;
        return <TextArea
            value={value || ''}
            onChange={this.onChange}
            growVertically
            fill
            large
            style={{ minHeight: 350 }}
            intent={Intent.PRIMARY}
            className=" p-4 "
        />;
    }

    isDesabled() {
        const { field, title } = this.props;
        const { value } = this.state;
        return <div className="px-0 my-2">
            {
                this.props.value
                    ? <Tag minimal>
                        { `${this.props.value} `}
                    </Tag>
                    : null
            }
        </div>;
    }

    onChange = evt => {
        this.setState({ value: evt.currentTarget.value });
        this.on(evt.currentTarget.value);
    }

    on = value => {
        this.props.on(value, this.props.field, this.props.title);
    }
}
