import React, { Fragment } from 'react';
import {
    graphql, compose, withApollo, Query,
} from 'react-apollo';
import { loader } from 'graphql.macro';
import Moment from 'react-moment';
import moment from 'moment';
import $ from 'jquery';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import {
    AnchorButton, Button, Classes, Dialog, Intent, Tooltip,
} from '@blueprintjs/core';
import Event from './Event';
import EventsFilters from './EventsFilters';
import Loading from '../../layouts/utilities/Loading';
import { __ } from '../../layouts/utilities/i18n';
import BasicState from '../../layouts/BasicState';
import {
    getQuery, getQueryArgs, getQueryName, queryCollection,
} from '../../layouts/schema';

import getWidget, { initArea } from '../../layouts/utilities/getWidget';

class MeetingState extends BasicState {
    getRoute = () => 'affiche'

    render() {
        const query_name = getQueryName('Meeting');
        const query_args = getQueryArgs('Meeting');

        const query = queryCollection('Meeting', query_name, query_args);

        return <div className="layout-state p-0">
            <div className="container joppa position-relative">
                <div className="row mt-4">
                    <div className="col-md-3">
                        <h1>
                            {__('Events')}
                        </h1>
                    </div>
                    <div className="col-md-9 m-main">
                        <div className="row">
                            <Query query={query}>
                                {
                                    ({
                                        loading, error, data, client,
                                    }) => {
                                        if (loading) {
                                            return <Loading />;
                                        }
                                        if (data) {
                                            const _events = data[query_name] || [];
                                            // console.log(_events);
                                            const events = _events
                                            // .filter(e => typeof e.title == "string" && e.title !== "")
                                                .map((e, i) => <div key={i} className="col-lg-4 col-md-4 col-12 my-3">
                                                    <Event {...e} />
                                                </div>);
                                            return events;
                                        }
                                        if (error) {
                                            return error.toString();
                                        }
                                    }
                                }
                            </Query>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

        onFilter = data => {

        }

        onStart = () => {

        }
}

export default compose(
    withApollo,
    withRouter,
)(MeetingState);
