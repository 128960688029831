import React, { Component, Fragment } from 'react';
import BasicState from '../../layouts/BasicState';
import config from '../../config/config.json';

class DriversState extends BasicState {
    myState = () => {
        const iframe = `<iframe  src="https://docs.google.com/spreadsheets/d/e/${config.drivers_google_spreadsheet_id}/pubhtml?widget=true&amp;headers=false" style="border: 0" width="100%" height="100%" frameborder="0" scrolling="no"></iframe>`;
        return <div
            dangerouslySetInnerHTML={{ __html: iframe }}
            style={{
                display: 'flex',
                justifyContent: 'stretch',
                alignItems: 'stretch',
                width: '100%',
                height: '100%',
            }}
        />;
    }

    getRoute = () => 'backlog'
}
export default DriversState;
