import React, { Component, Fragment, useContext } from 'react';
import gql from 'graphql-tag';
import Graph from 'vis-react';
import { compose } from 'recompose';
import { loader } from 'graphql.macro';
import { Query, withApollo, graphql } from 'react-apollo';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import BasicStateFunctional from '../BasicStateFunctional';
import CategoryForm from '../DataTableState/DataTable/CategoryForm';
import { onSaveGql } from '../../layouts/schema/ecosystem';

const QuizesState = function (props) {
    let { loading, data } = useQuery(gql`
        query getQuizes {
            getQuizes {
                _id
                title
            }
        }
    `, { client: props.client });
    if (loading) {
        return null;
    }
    data = data.getQuizes;
    data = data.map(e => <div><Link to={`${props.match.path}/${e._id}`}>{e.title}</Link></div>);
    return <BasicStateFunctional
        title="Образовательная карта"
        {...props
        }
    >
        {data}
    </BasicStateFunctional>;
};

export default compose(

    withApollo,
    withRouter,
)(QuizesState);
