import React, { Component, Fragment } from 'react';

class EventsFilters extends Component {
    constructor(props) {
        super(props);
        this.state.panelHtml = 'Boonbooobooooo';
    }

    render() {
        return 'EventsFilters';
    }
}

export default EventsFilters;
