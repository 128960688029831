import React, { Component, Fragment } from 'react';
import {
    Tabs, Tab, Button, ButtonGroup, Classes, Dialog, Intent, Tooltip, Callout, Card, Popover, Position,
} from '@blueprintjs/core';
import Moment from 'react-moment';
import moment from 'moment';
import { compose } from 'recompose';
import { loader } from 'graphql.macro';
import { Query, withApollo, graphql } from 'react-apollo';
import { withRouter } from 'react-router';
import gql from 'graphql-tag';
import { __ } from '../../layouts/utilities/i18n';
import { sprintf } from '../../layouts/utilities/sprintf';
import Loading from '../../layouts/utilities/Loading';
import compaireArrays, { removeArrayFromArray } from '../../layouts/utilities/compaireArrays';
import { MultiSelect } from '../../layouts/FieldInput';
import CategoryForm from '../DataTableState/DataTable/CategoryForm';
import PlaceEventTabs from './PlaceEventTabs';
import PlaceList from './PlaceList';

const getPlaces = loader('./graphql/getPlaces.graphql');
const addMeToPlace = loader('./graphql/addMeToPlace.graphql');
const removeMeFromPlace = loader('./graphql/removeMeFromPlace.graphql');
const getCurrentUser = loader('./graphql/getCurrentUser.graphql');
const getMyTeams = loader('./graphql/getMyTeams.graphql');
const addTeamToPlace = loader('./graphql/addTeamToPlace.graphql');
const removeTeamFromPlace = loader('./graphql/removeTeamFromPlace.graphql');
const changePlace = loader('./graphql/changePlace.graphql');
const deletePlace = loader('./graphql/deletePlace.graphql');
const mergePlaces = loader('./graphql/mergePlaces.graphql');

class PlaceTabs extends Component {
    state = {
        navbarTabId: 'PlaceTab',
        mergedPlaceTitle: '',
        isMergeOpen: false,
        isTeamLoading: false,
        ...this.props,
        oldTeams:
            Array.isArray(this.props.teams)
                ? this.props.teams
                : [],
    }

    render() {
        if (this.props.getCurrentUser.loading || this.props.getMyTeams.loading) return <Loading />;
        // console.log( this.props );
        return <>
            <Tabs
                id="TabsExample"
                onChange={this.handleTabChange}
                selectedTabId={this.state.navbarTabId}
                vertical
                animated
            >
                <Tab
                    id="PlaceTab"
                    title={__(this.state.type_new ? this.state.type_new.title : '')}
                    panel={this.placeTab()}
                />
                <Tab id="EventsTab" title={__('Events')} panel={this.eventsTab()} />
                <Tab id="MembersTab" title={__('Members')} panel={this.membersTab()} />
                <Tab id="TeamsTab" title={__('Teams')} panel={this.teamsTab()} />
                {
                    this.props.getCurrentUser.getCurrentUser
                    && (
                        this.props.getCurrentUser.getCurrentUser._id == this.props.owner._id
                        || this.props.getCurrentUser.getCurrentUser.roles.filter(e => e == 'admin' || e == 'moderator').length > 0
                    )
                        ? <Tab id="EditTab" title={__('Edit')} panel={this.editTab()} />
                        : null
                }
                {
                    this.props.getCurrentUser.getCurrentUser
                    && (
                        this.props.getCurrentUser.getCurrentUser._id == this.props.owner._id
                        || this.props.getCurrentUser.getCurrentUser.roles.filter(e => e == 'admin').length > 0
                    )
                        ? <Tab id="MergeTab" title={__('Merge')} panel={this.mergeTab()} />
                        : null
                }

                <Tabs.Expander />
                <Button onClick={this.props.onClose} fill className="mt-4">{__('Close')}</Button>
            </Tabs>
            <Dialog
                icon="issue"
                isOpen={this.state.isMergeWinOpen}
                onClose={() => this.setState({ isMergeWinOpen: false })}
                title={__('Attention')}
            >
                <div className="pt-dialog-body-min p-3">
                    <div className="text-danger lead mb-3 text-center">
                        {__('Pay special attention')}
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: sprintf(
                                __('The merge operation will delete this Place %s and transfer all members and teams to the specified Place - %s.'),
                                `<b>${this.state.title}</b>`,
                                `<b>${this.state.mergedPlaceTitle}</b>`,
                            ),
                        }}
                        className=" text-center"
                    >
                    </div>
                </div>
                <div className="pt-dialog-footer p-3">
                    <div className="pt-dialog-footer-actions center">
                        <Button
                            intent={Intent.DANGER}
                            text={__('Yes, start merge.')}
                            onClick={this.onStartMerge}
                        />
                    </div>
                </div>
            </Dialog>
        </>;
    }

    handleTabChange = navbarTabId => {
        this.setState({ navbarTabId });
    }

    placeTab() {
        // console.log(this.props);
        const descr = this.props.description ? <div className="place-descr my-3">{this.props.description}</div> : '';
        const address = <div className="place-descr">{this.props.address}</div>;
        const chat = this.props.chat
            ? this.props.chat.external_url
                ? <div className="map-descr">
                    <a href={this.props.chat.external_url} target="_blank" rel="noreferrer">
                        {__('Telegram chat')}
                        <b className="ml-1">
                            {this.props.chat.title}
                            {' '}
                        </b>
                    </a>
                </div>
                : <div className="map-descr">
                    {__('Telegram chat')}
                    <b className="ml-1">
                        {this.props.chat.title}
                        {' '}
                    </b>
                </div>
            : <div className="map-descr">{ __("Place hasn't a telegram chat")}</div>;
        return <div className="p-tab">
            <div className="lead ">{this.props.title}</div>
            {address}
            {descr}
            {chat}
            <div>

            </div>
        </div>;
    }

    eventsTab() {
        return <PlaceEventTabs
            events={this.props.events}
            onEvent={this.props.onEvent}
            _id={this.props._id}
            currentUser={this.props.getCurrentUser.getCurrentUser}
        />;
    }

    membersTab() {
        const members = this.state.members && this.state.members.length > 0
            ? this.state.members.map((e, i) => <li className="list-group-item" key={i}>
                {`${e.name ? e.name : ''} ${e.family_name ? e.family_name : ''}`}
            </li>)
            : <Callout>{__('No members exists.')}</Callout>;

        const btn = this.isMember()
            ? <Button
                intent={Intent.DANGER}
                text={__('remove from members of ') + this.props.title}
                onClick={this.onRemoveMember}
            />
            : <Button
                intent={Intent.SUCCESS}
                text={__('add to members of ') + this.props.title}
                onClick={this.onAddMember}
            />;

        return <div className="p-tab">
            <ul className="list-group">
                <div className="">{__('Author')}</div>
                <li className="list-group-item mb-2">
                    {
                        this.props.owner
                            ? `${this.props.owner.name ? this.props.owner.name : ''
                            } ${
                                this.props.owner.family_name ? this.props.owner.family_name : ''}`
                            : null
                    }
                </li>
                <div className="">{__('Members')}</div>
                {members}

            </ul>
            <div className="pt-3">
                {this.props.getCurrentUser.getCurrentUser ? btn : null}
            </div>
        </div>;
    }

    teamsTab() {
        const allTeams = Array.isArray(this.props.getMyTeams.getMyTeams) ? this.props.getMyTeams.getMyTeams : [];
        const teams = this.state.teams || [];
        const _teams = teams.length > 0
            ? teams.map((e, i) => <li className="list-group-item" key={i}>{e.title}</li>)
            : <Callout>{__('No Teams exists.')}</Callout>;
        return <div className="p-tab">
            <div className="lead">
                {__('All Teams in Place')}
            </div>
            <ul className="list-group mb-3">
                {_teams}
            </ul>
            {
                allTeams.length > 0
                    ? <>
                        <div className={this.state.isTeamLoading ? 'pre-hidden' : ''}>
                            <MultiSelect
                                multiple
                                field="teams"
                                editable
                                title={sprintf(__('My Teams in %s'), this.props.title)}
                                value={teams}
                                data={allTeams}
                                visibled_value="title"
                                vertical
                                on={this.onTeams}
                            />
                        </div>
                        <div className="small text-secondary">
                            {__('Add one or some mine Teams to Place')}
                        </div>
                    </>
                    : null
            }
        </div>;
    }

    onSave = async data => {
        console.log(1);

        console.log(this.state);
        const vars = {
            id: this.state._id,
            title: this.state.title,
            geo: this.state.geo,
            description: this.state.description,
            information: this.state.information,
            type_new_id: this.state.type_new ? this.state.type_new._id : null,
            owner_id: this.state.owner ? this.state.owner._id : null,
            members_ids: this.state.members ? this.state.members.map(e => e._id) : null,
            teams_ids: this.state.teams ? this.state.teams.map(e => e._id) : null,
            address: this.state.address,
        };

        // console.log(vars);
        this.props.changePlace({
            variables: vars,
            update: (store, update) => {
                console.log(update);
                if (update.data.changePlace) {
                    this.props.onClose();
                }
            },
            refetchQueries: [
                { query: getPlaces, variables: {} },
            ],
        });
    }

    onChange=(field, value, id) => {
        console.log(field, value, id);
        const state = { ...this.state };
        state[value] = field;
        console.log(state);

        this.setState(state, console.log(this.state, state));
    }

    onClose = data => {

    }

    onStartMerge = evt => {
        console.log(`start merge place ${this.props._id}to place ${this.state.merged_id}`);

        this.props.mergePlaces({
            variables: {
                to_id: this.state.merged_id,
                from_id: this.state._id,

            },
            update: (store, update) => {
                console.log(update);
                if (update.data.mergePlaces) {
                    // this.props.onClose();
                    this.setState({ merged_id: -1, isMergeWinOpen: false }, this.props.onMerged);
                }
            },
            refetchQueries: [
                { query: getPlaces, variables: {} },
            ],
        });
    }

    editTab = () =>
    // console.log( this.props );
        <>
            <div className="p-tab">
                <div>
                    <CategoryForm
                        {...this.state}
                        ID={this.state._id}
                        data={this.state}
                        data_type="Place"
                        on={this.onChange}
                        onChange={this.onChange}
                        // onSave={(state, _id) => this.onSave( state, _id)}
                        onDelete={this.onDelete}
                        onClose={this.onClose}
                        vertical
                        isHiddenSave
                        isHiddenClose
                        isHiddenDelete
                        isOpen // for Yandex Maps
                        ref={node => { this.card = node; }}
                    />
                </div>

            </div>
            <div className="p-1">
                <Button text={__('Edit')} onClick={this.onSave} />
                <Button text={__('Delete')} onClick={this.onDelete} intent={Intent.DANGER} />

            </div>
        </>

    //                <Popover
    //                     position={Position.TOP_LEFT}
    //                     className="hidden"
    //                     isOpen={this.state.isMergeOpen}
    //                     content={
    //                         <div className="square p-0" >
    //                             <div className="p-0">
    //                                 <PlaceList onChoose={this.onChoose} />
    //                             </div>
    //                         </div>
    //                     }
    //                 >
    //                     <Button text={__("Merge this Place to another")} onClick={this.onMerge} />
    //                 </Popover>
    //            <div className="p-1">
    //                 <Button text={__("Merge this Place to another")} onClick={this.onMerge} />
    //             </div>
    mergeTab = () => <>
        <div className="p-tab">
            <div className="p-0">
                <div className="p-0">
                    <PlaceList _id={this.props._id} onChoose={this.onChoose} />
                </div>
            </div>
        </div>
    </>

    onChoose = (id, mergedPlaceTitle) => {
        this.setState({
            isMergeOpen: false,
            isMergeWinOpen: true,
            mergedPlaceTitle,
            merged_id: id,
        });
    }

    onTeams = data => {
        const add = [];
        const remove = [];
        const new_list = data.map(e => e._id);
        const old_list = this.state.oldTeams.map(e => e._id);
        new_list.forEach(e => {
            if (!old_list.includes(e)) {
                add.push(e);
            }
        });
        old_list.forEach(e => {
            if (!new_list.includes(e)) {
                remove.push(e);
            }
        });
        this.state.oldTeams = data;
        add.forEach(e => this.onAddTeam({ _id: e }));
        remove.forEach(e => this.removeAddTeam({ _id: e }));

        // let oldTeams = [...this.state.oldTeams];
        // oldTeams = oldTeams.concat(add);
        // oldTeams = removeArrayFromArray(remove, oldTeams);
        // this.setState({oldTeams, teams:oldTeams, isTeamLoading:false});
    }

    isMember() {
        return Array.isArray(this.state.members) && this.props.getCurrentUser.getCurrentUser
            ? this.state.members.filter(e => e._id === this.props.getCurrentUser.getCurrentUser._id).length > 0
            : false;
    }

    //
    onAddMember = () => {
        const dat = {
            place_id: this.props._id,
        };
        console.log(dat);
        this.props.addMeToPlace({
            variables: dat,
            update: (store, update) => {
                console.log(update.data);
                this.setState({ members: update.data.addMeToPlace.members });
            },
        });
    }

    onMerge = () => {
        this.setState({ isMergeOpen: !this.state.isMergeOpen });
    }

    onDelete = () => {
        const dat = {
            id: this.props._id,
        };
        console.log(dat);
        this.props.deletePlace({
            variables: dat,
            update: (store, update) => {
                if (update.data.deletePlace) {
                    this.props.onClose();
                }
            },
            refetchQueries: [
                { query: getPlaces, variables: {} },
            ],
        });
    }

    onRemoveMember = () => {
        const dat = {
            place_id: this.props._id,
        };
        console.log(dat);
        this.props.removeMeFromPlace({
            variables: dat,
            update: (store, update) => {
                console.log(update.data.removeMeFromPlace);
                this.setState({ members: update.data.removeMeFromPlace.members });
            },
        });
    }

    onAddTeam = data => {
        this.setState({ isTeamLoading: true });
        const dat = {
            place_id: this.props._id,
            team_id: data._id,
        };
        console.log(dat);
        this.props.addTeamToPlace({
            variables: dat,
            update: (store, update) => {
                console.log(update.data.addTeamToPlace);
                this.setState({ teams: update.data.addTeamToPlace.teams, isTeamLoading: false });
            },
        });
    }

    removeAddTeam = data => {
        this.setState({ isTeamLoading: true });
        const dat = {
            place_id: this.props._id,
            team_id: data._id,
        };
        console.log(dat);
        this.props.removeTeamFromPlace({
            variables: dat,
            update: (store, update) => {
                console.log(update.data.removeTeamFromPlace);
                this.setState({ teams: update.data.removeTeamFromPlace.teams, isTeamLoading: false });
            },
        });
    }
}
export default compose(
    graphql(getCurrentUser, { name: 'getCurrentUser' }),
    graphql(getMyTeams, { name: 'getMyTeams' }),
    graphql(addMeToPlace, { name: 'addMeToPlace' }),
    graphql(removeMeFromPlace, { name: 'removeMeFromPlace' }),
    graphql(addTeamToPlace, { name: 'addTeamToPlace' }),
    graphql(removeTeamFromPlace, { name: 'removeTeamFromPlace' }),
    graphql(changePlace, { name: 'changePlace' }),
    graphql(deletePlace, { name: 'deletePlace' }),
    graphql(mergePlaces, { name: 'mergePlaces' }),
    withApollo,
    withRouter,
)(PlaceTabs);
