import React, {
    Component, Fragment, useContext, useState,
} from 'react';
import gql from 'graphql-tag';
import { compose } from 'recompose';
import { loader } from 'graphql.macro';
import { Query, withApollo, graphql } from 'react-apollo';
import { withRouter } from 'react-router';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { Card, Intent } from '@blueprintjs/core';
import FormEditState from '../FormEditState';
import BasicStateFunctional from '../BasicStateFunctional';
import { __ } from '../../layouts/utilities/i18n';
import {
    getQueryName, getQueryArgs,
    queryCollection, querySingle,
    getChangeName, getInputTypeName, getMutationArgs,
    mutationAdd, mutationEdit, mutationDelete,
} from '../../layouts/schema';
import { AppToaster } from '../../layouts/utilities/blueUtils';
import UserContext from '../../layouts/userContext';

const query = loader('../OldProfileState/graphql/getCurrentUser.graphql');
const change_mutation = loader('../OldProfileState/graphql/changeCurrentUser.graphql');

const NewProfileState = function (props) {
    const context = useContext(UserContext);
    return <BasicStateFunctional
        title="Профиль"
        {...props
        }
    >
        <FormEditState
            ID="ID"
            data_type="UserCurrentInput"
            // isHiddenSave={true}
            query={query}
            query_name="getCurrentUser"
            change_mutation={change_mutation}
            isHiddenClose
            isHiddenDelete
            onChange={user => {
                context.setUser(user.changeCurrentUser);
                AppToaster.show({
                    intent: Intent.SUCCESS,
                    icon: 'tick',
                    message: __('Profile changed'),
                });
            }}
        />
    </BasicStateFunctional>;
};

export default compose(

    withApollo,
    withRouter,
)(NewProfileState);
