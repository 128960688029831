import React, {
    Component, Fragment, useContext, useState,
} from 'react';
import gql from 'graphql-tag';
import { compose } from 'recompose';
import { loader } from 'graphql.macro';
import { Query, withApollo, graphql } from 'react-apollo';
import { withRouter } from 'react-router';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
    Card,
} from '@blueprintjs/core';
import CategoryForm from '../DataTableState/DataTable/CategoryForm';
import {
    onSaveGql,
    apolloFields,
    getInput, getVisibleValue,
} from '../../layouts/schema/ecosystem';
import {
    getQueryName, getQueryArgs,
    queryCollection, querySingle,
    getChangeName, getInputTypeName, getMutationArgs,
    mutationAdd, mutationEdit, mutationDelete,
} from '../../layouts/schema';

import BasicStateFunctional from '../BasicStateFunctional';

const FormEditState = function (props) {
    const { data_type } = props;

    let query_name = `get${data_type}`;
    const query_args = getQueryArgs(data_type);
    let query = querySingle(data_type, query_name, query_args, props.ID);

    const mutation_name = getChangeName(data_type);
    const input_type_name = getInputTypeName(data_type);
    const mutation_args = getMutationArgs(data_type);

    let change_mutation = mutationEdit(data_type, mutation_name, input_type_name, mutation_args);
    const delete_mutation = mutationDelete(data_type);

    if (props.query) {
        query = props.query;
    }
    if (props.change_mutation) {
        change_mutation = props.change_mutation;
    }
    if (props.query_name) {
        query_name = props.query_name;
    }

    // let [state, setState] = useState({});
    const [load, { loading, data: query_data }] = useLazyQuery(query, { client: props.client });
    const [edit_mutation_hook] = useMutation(change_mutation, { client: props.client, onCompleted: props.onChange });
    const [delete_mutation_hook] = useMutation(delete_mutation, { client: props.client, onCompleted: props.onChange });
    let data = {};
    if (props.ID) {
        if (loading) {
            return null;
        }
        if (query_data) {
            data = query_data[query_name];
        } else {
            load();
        }
    }
    // console.log(data);

    function onSave(state) {
        const input = getInput(state, props.data_type);
        edit_mutation_hook({
            variables:
            {
                id: this.ID,
                input,
            },
        });
    }
    function onDelete() {
        delete_mutation_hook({
            variables:
            {
                id: this.ID,
            },
        });
    }

    return <div style={{ 'background-color': '#777777' }}>
        <Card
            fill="true"
            className="layout-data-card p-4 mb-1"
            interactive={false}
        >
            <CategoryForm
                ID={props.ID}
                data_type={props.data_type}
                data={data}
                // onChange={this.onChange}
                onSave={onSave}
                onDelete={onDelete}
                // onClose={this.onClose}
                vertical
                isHiddenSave={props.isHiddenSave}
                isHiddenClose={false}
                isHiddenDelete={props.isHiddenDelete}
                isOpen
            />
        </Card>
    </div>;
};

export default compose(

    withApollo,
    withRouter,
)(FormEditState);
