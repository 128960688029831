import React, { Fragment } from 'react';
import LayoutsProvider from '../layouts/LayoutsProvider';

import LayoutAccessLoading from './LayoutAccessLoading';

import { client } from '../layouts/client';

// import './css/blueprint-icons.css';
import './css/bootstrap.min.css';
import './css/blueprint.css';
import './css/phone.css';
import './css/style.css';

class LayoutApp extends React.Component {
    render() {
        return (
            <>
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" />
                <link href="https://fonts.googleapis.com/css?family=Amatic+SC|Ubuntu+Condensed|Yanone+Kaffeesatz|Open+Sans+Condensed:300|Open+Sans:300|Yanone+Kaffeesatz:200&display=swap" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css?family=PT+Sans:regular,italic,bold,bolditalic" rel="stylesheet" type="text/css" />
                <LayoutsProvider>
                    <LayoutAccessLoading />
                </LayoutsProvider>
            </>
        );
    }
}

export default LayoutApp;
