import React, { Component, Fragment } from 'react';
import { NavLink, Link } from 'react-router-dom';
import BasicState from '../../layouts/BasicState';
import { __ } from '../../layouts/utilities/i18n';
import layouts from '../../layouts/layouts';

// out
class MainAdminState extends BasicState {
    render() {
        const menus = layouts.routing.menu[0].children.map((e, i) => {
            const url = `${layouts.routing.menu[0].route}/${e.route}`;
            return i === 0
                ? <div className="hidden1" key={i}>
                </div>
                : <div className="col-4 mb-3" key={i}>
                    <div className="card bg-light text-center" style={{ width: '100%' }}>
                        <div className="card-body">
                            <h5 className="card-title">
                                {__(e.title)}
                            </h5>
                            <p className="card-text">
                                Описание
                                {' '}
                                {__(e.title)}
                            </p>
                            <NavLink
                                to={e.route}
                                className="btn btn-primary text-right"
                            >
                                Перейти
                            </NavLink>
                        </div>
                    </div>
                </div>;
        });
        // console.log( menus )
        return <div className="layout-state">
            <div className="layout-state-head">
                <span className={`layout-state-logo ${this.state.route.icon}`} />
                <div className="layout-state-title">
                    { __(this.state.route.title) }
                </div>
            </div>
            <div className="row">
                {menus}
            </div>
        </div>;
    }

    getRoute = () => 'admin'
}
export default MainAdminState;
