import React, { Component, Fragment } from 'react';
import {
    Icon, Tag,
    Intent, Tooltip,
    Card, FormGroup,
    Button, ButtonGroup,
    Position, Popover,
    InputGroup,
} from '@blueprintjs/core';

import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import { AppToaster } from '../../layouts/utilities/blueUtils';
import { __ } from '../../layouts/utilities/i18n';

const l_token = loader('../../layouts/schema/graphql/token.graphql');
const l_userInfo = loader('../../layouts/schema/graphql/userInfo.graphql');

// with login state
class ScopeState extends Component {
    // state={login: "", password: ""}

    render() {
        return <div className="layout-center">
            <Card
                interactive
                className="layout-message p-4 layout-center"
            >
                <div className="title mb-auto">{__('Scopes')}</div>
                <div className="my-3 text-light text-center">
                    {this.props.text}
                </div>
                <div className="btn-group">
                    <div className="btn btn-primary btn-fill" onClick={this.onAccept}>
                        {__('Accept')}
                    </div>
                    <div className="btn btn-danger btn-fill" onClick={this.onDecline}>
                        {__('Decline')}
                    </div>
                </div>
            </Card>
        </div>;
    }

    onAccept = () => {
        this.props.token({
            variables:
                {
                    input: {
                        grant_type: 'ciba',
                        assertion: process.env.REACT_APP_ASSERTION,
                        auth_req_id: this.state.auth_req_id,
                    },
                },
            update: (store, { data: { token } }) => {
                AppToaster.show({
                    intent: Intent.SUCCESS,
                    icon: 'tick',
                    message: __('You enter by User'),
                });
                this.setState({ logged: 2 });
                localStorage.setItem(
                    'token',
                    token.access_token,
                );
                // TODO: get userInfo and put to store
                this.props.history.replace('/');
            },
            refetchQueries: [{ query: l_userInfo, variables: {} }],
        });
    }

    onDecline =() => {
        this.setState({ logged: 0 });
    }
}

export default compose(

    graphql(l_token, { name: 'token' }),
    withApollo,
    withRouter,
)(ScopeState);
