import React, {
    Component, Fragment, useState, useContext,
} from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { client } from './client';

import LayoutUserProvider from './LayoutUserProvider';
import LayoutScrollToTop from './LayoutScrollToTop';

function LayoutsProvider(props) {
    return (
        <ApolloProvider client={client()}>
            <BrowserRouter>
                <LayoutScrollToTop />
                <LayoutUserProvider>
                    {props.children}
                </LayoutUserProvider>
            </BrowserRouter>
        </ApolloProvider>
    );
}

export default LayoutsProvider;
