import React, {
    Component, Fragment, useContext, useState,
} from 'react';
import gql from 'graphql-tag';
import Graph from 'vis-react';
import { compose } from 'recompose';
import { loader } from 'graphql.macro';
import { Query, withApollo, graphql } from 'react-apollo';
import {
    withRouter, Switch, Route, useRouteMatch, useLocation, useParams,
} from 'react-router';
import { Link } from 'react-router-dom';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import Moment from 'react-moment';
import CategoryForm from '../DataTableState/DataTable/CategoryForm';
import { onSaveGql } from '../../layouts/schema/ecosystem';
import BasicStateFunctional from '../BasicStateFunctional';

const Single = function (props) {
    const { data } = props;
    const location = useLocation();
    const [load, { loading, data_single }] = useLazyQuery(gql`
        query getProposal($id: ID!) {
            getProposal(id: $id) {
                _id
                title
                date
                author {name family_name telegram_id}
                circle {title}
                votes {date type author {name family_name telegram_id}}
            }
        }
    `, { client: props.client });
    const [vote] = useMutation(gql`
        mutation voteProposal($proposal_id: ID, $type: String) {
            voteProposal(proposal_id: $proposal_id, type: $type) {
                _id
                title
        }
    }
    `, { client: props.client, onCompleted: result => { load({ variables: { id: result.voteProposal._id } }); } });
    const e = data_single ? data_single.getProposal : data;
    return <div key={e._id}>
        <h2 style={{ whiteSpace: 'pre-wrap' }}><Link to="../">{e.title.trim()}</Link></h2>
        <div>
            {e.author ? (`${e.author.name} ${e.author.family_name}`) : ''}
,
            {' '}
            {moment(e.date).format('YYYY-MM-DD HH:mm:ss')}
        </div>
        <div>{e.circle ? e.circle.title : ''}</div>
        <div>
            <ul>
                {
                    e.votes ? e.votes.map(vote => <li key={vote._id}>
                        {vote.author ? (`${vote.author.name} ${vote.author.family_name}`) : ''}
:
                        {vote.type}
                        {' '}
(
                        {moment(vote.date).format('YYYY-MM-DD HH:mm:ss')}
)
                    </li>) : null
                }
            </ul>
        </div>
        <div>
            <button onClick={() => { vote({ variables: { proposal_id: e._id, type: 'yes' } }); }}>yes</button>
            <button onClick={() => { vote({ variables: { proposal_id: e._id, type: 'no' } }); }}>no</button>
            <button onClick={() => { vote({ variables: { proposal_id: e._id, type: 'doubt' } }); }}>doubt</button>
        </div>
    </div>;
};

const VotesStates = function (props) {
    let { loading, data } = useQuery(gql`
        query getProposals {
            getProposals {
                _id
                title
                date
                author {name family_name telegram_id}
                circle {title}
            }
        }
    `, { client: props.client });
    const location = useLocation();
    const [filter, setFilter] = useState('');

    if (loading) {
        return null;
    }

    const getHighlightedText = function (text, highlight) {
        if (highlight === '') {
            return text;
        }
        const parts = text.split(new RegExp(`(${highlight})`, 'g'));
        return <>
            { parts.map((part, i) => <span key={i} style={part === highlight ? { backgroundColor: 'yellow' } : {}}>
                { part }
            </span>)}
            {' '}
        </>;
    };

    data = data.getProposals;
    data = <>
        <style>
            {
                `.table-border td, .table-border th {
                    border: solid 1px;
                }`
            }
        </style>
        <div>
Фильтр:
            <input value={filter} onChange={e => setFilter(e.target.value)} />
        </div>
        <table className="table table-border mb-5">
            <tr>
                <th>Название</th>
                <th>Дата</th>
                <th>Круг</th>
                <th>Автор</th>
            </tr>
            {data.filter(proposal => filter === '' || proposal.title.includes(filter)).map(proposal => <tr key={proposal.id}>
                <td>
                    <b style={{ whiteSpace: 'pre-wrap' }}>
                        <Link to={`${location.pathname}/vote/${proposal._id}`}>
                            {getHighlightedText(proposal.title.trim(), filter)}
                        </Link>
                    </b>
                </td>
                <td>
                    <Moment locale="ru" format="D MMMM YYYY, h:mm">
                        {new Date(proposal.date)}
                    </Moment>
                </td>
                <td>{proposal.circle ? proposal.circle.title : null}</td>
                <td>{proposal.author ? `${proposal.author.name} ${proposal.author.family_name}` : null}</td>
            </tr>)}
        </table>
    </>;

    return <BasicStateFunctional
        title="Результаты голосований"
        {...props
        }
    >
        {data}
    </BasicStateFunctional>;
};

const VotePage = function (props) {
    const params = useParams();
    const { loading, data } = useQuery(gql`
        query getProposal {
            getProposal(id: "${params.proposalId}") {
                _id
                title
                date
                author {name family_name telegram_id}
                circle {title}
                votes {date type author {name family_name telegram_id}}
            }
        }
    `, { client: props.client });

    if (loading) {
        return null;
    }
    return <BasicStateFunctional
        title="Результаты голосований"
        {...props
        }
    >
        <Single data={data.getProposal} {...props} />
    </BasicStateFunctional>;
};

const VotesRoute = function (props) {
    const match = useRouteMatch();
    return <>
        <Switch>
            <Route path={`${match.url}/vote/:proposalId`}>
                <VotePage {...props} />
            </Route>
            <Route path="/">
                <VotesStates {...props} />
            </Route>
        </Switch>
    </>;
};

export default compose(

    withApollo,
    withRouter,
)(VotesRoute);
