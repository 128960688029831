import React, { Component, Fragment } from 'react';
import { graphql, compose, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import Moment from 'react-moment';
import moment from 'moment';
import $ from 'jquery';
import Graph from 'vis-react';
import Fullscreen from 'react-full-screen';
import LayoutIcon from '../../layouts/LayoutIcon';
import Loading from '../../layouts/utilities/Loading';
import { __ } from '../../layouts/utilities/i18n';
import BasicState from '../../layouts/BasicState';

const getTeams = loader('./graphql/getTeams.graphql');

class TeamsMindState extends BasicState {
    getRoute = () => 'map-teams'

    render() {
        if (this.props.loadingPlaces) return <Loading />;
        console.log(this.props);
        return <div className="layout-state">
            <script src="https://cdnjs.cloudflare.com/ajax/libs/vis/4.21.0/vis.min.js"></script>
            <link href="https://cdnjs.cloudflare.com/ajax/libs/vis/4.21.0/vis.min.css" rel="stylesheet" type="text/css" />
            <div className="layout-state-head">
                <LayoutIcon
                    isSVG
                    src={this.state.route.icon}
                    className="layout-state-logo "
                />
                <div className="layout-state-title">
                    { __(this.state.route.title) }
                </div>
                <div className="layout-state-heaader">
                    {this.state.panelHtml}
                </div>
            </div>
            { this.getGraph(this.props.teams) }
        </div>;
    }

    getGraph(data) {
        const nodes = []; const users = []; const edges = []; let n = 0; let
            team = 0;
        data.forEach((e, i) => {
            // add team
            n++;
            team = n;
            nodes.push({
                id: n,
                label: e.title,
                color: '#FF7777',
                tid: e._id,
                heightConstraint: true,
                shadow: { enabled: true },
                shape: 'box',
                margin: 10,
            });
            e.member.forEach((ee, ii) => {
                // add member - team
                const user = nodes.filter(eee => eee.uid == ee._id)[0];
                if (user) {
                    console.log('from: ', user.label, 'to: ', e.title);
                    edges.push({ from: user.id, to: team });
                    return;
                }
                edges.push({ from: n + 1, to: team });
                n++;
                const nm = `${ee.first_name ? ee.first_name : ''} ${ee.second_name ? ee.second_name : ''}`;
                nodes.push({
                    id: n,
                    label: nm,
                    color: '#7777FF',
                    uid: ee._id,
                    heightConstraint: true,
                    shadow: { enabled: true },
                    shape: 'box',
                    margin: 10,
                });
            });
        });
        const graph = {
            nodes,
            edges,
        };

        const options = {
            layout:
            {
                hierarchical: false,
            },
            edges:
            {
                color: '#000000',
            },
            physics:
            {
                enabled: true,
                barnesHut:
                {
                    gravitationalConstant: -2000,
                    centralGravity: 0.009,
                    springLength: 200,
                    springConstant: 0.7,
                    damping: 0.09,
                    avoidOverlap: 0.49,
                },
                forceAtlas2Based:
                {
                    gravitationalConstant: 50,
                    centralGravity: 0.01,
                    springConstant: 0.08,
                    springLength: 100,
                    damping: 0.4,
                    avoidOverlap: 0.49,
                },
                repulsion:
                {
                    centralGravity: 0.0001,
                    springLength: 200,
                    springConstant: 0.05,
                    nodeDistance: 100,
                    damping: 0.09,
                    avoidOverlap: 4,
                },
                hierarchicalRepulsion:
                {
                    centralGravity: 0.0,
                    springLength: 100,
                    springConstant: 0.01,
                    nodeDistance: 120,
                    damping: 0.09,
                },
                maxVelocity: 1,
                minVelocity: 0.1,
                solver: 'repulsion',
                stabilization:
                {
                    enabled: true,
                    iterations: 1000,
                    updateInterval: 100,
                    onlyDynamicEdges: false,
                    fit: false,
                },
                timestep: 0.5,
                adaptiveTimestep: true,
            },
        };

        const events = {
            select(event) {
                const { nodes, edges } = event;
            },
        };
        return <Fullscreen
            enabled={this.state.isFull}
            onChange={isFull => this.setState({ isFull })}
        >
            <div className="position-relative bg-light height_100">
                <Graph
                    graph={graph}
                    options={options}
                    events={events}
                    style={{ height: '100%', minHeight: 500 }}
                    getNetwork={this.getNetwork}
                    getEdges={this.getEdges}
                    getNodes={this.getNodes}
                    vis={vis => (this.vis = vis)}
                />
                <div className="btn btn-light btn-sm position-absolute right" onClick={this.goFull}>
                     +
                </div>
            </div>
        </Fullscreen>;
    }

    goFull = () => {
        this.setState({ isFull: !this.state.isFull });
    }

    getTitle() {
        return 'Люди, команды на карте';
    }
}

export default compose(
    graphql(getTeams,
        {
            options: props => ({
                variables: { },
                name: 'getTeams',
            }),
            props: p => {
                const { data } = p;
                console.log('getTeams', p);
                return { loadingPlaces: data.loading, teams: data.getTeams };
            },
        }),
    withApollo,
)(TeamsMindState);
