import React, { Component, Fragment } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import $ from 'jquery';
import { withRouter } from 'react-router';
import {
    AnchorButton, Button, ButtonGroup, Classes, Dialog, Intent, Tooltip, FormGroup, InputGroup, TextArea,
} from '@blueprintjs/core';
import { compose } from 'recompose';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import CategoryForm from '../DataTableState/DataTable/CategoryForm';
import {
    String, URL, DateTime, Radio, Geo, TextField,
} from '../../layouts/FieldInput';
import Loading from '../../layouts/utilities/Loading';
import { __ } from '../../layouts/utilities/i18n';

class NewPlaceForm extends Component {
    state={ ...this.props };

    render() {
        const th = this;
        if (this.props.geo != this.state.last_geo) {
            if (this.state.address) {
                th.state.address = '';
                th.setState(th.state, () => { th.props.on('address', ''); });
            }
            window.ymaps.geocode(this.props.geo || this.state.new_mark_coords, { results: 1, kind: 'house' })
                .then(res => {
                    const firstGeoObject = res.geoObjects.get(0);
                    const adress = firstGeoObject.getLocalities().length
                        ? firstGeoObject.properties.get('metaDataProperty').GeocoderMetaData.AddressDetails.Country.AddressLine
                        : firstGeoObject.getAdministrativeAreas();
                    th.state.address = adress;
                    th.state.last_geo = th.props.geo;
                    th.setState(th.state, () => { th.props.on('address', adress); });
                });
        }
        // console.log(this.props.place_types);

        const { place_types } = this.props;
        const state = { ...this.state, geo: this.state.geo || this.state.new_mark_coords };
        return <section>
            {
            }
            <CategoryForm
                {...state}
                ID={null}
                data={state}
                data_type="Place"
                on={this.onChange}
                onChange={this.onChange}
                // onSave={(state, _id) => this.onSave( state, _id)}
                onDelete={this.onDelete}
                onClose={this.onClose}
                vertical
                isHiddenSave
                isHiddenClose
                isHiddenDelete
                isOpen
            />
        </section>;
    }

    onChange=(field, value, id) => {
        console.log(field, value, id);
        const state = { ...this.state };
        state[value] = field;
        this.setState(state, function () {
            console.log(this.state);
            this.props.on(field, value);
        });
    }
}

export default NewPlaceForm;
